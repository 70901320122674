import React from "react"
import styles from "./404.module.css"
import { Link } from "gatsby"
import Metadata from "../components/metadata"

function NotFoundPage() {
  const title = "404 - Page Not Found 😕"
  const description =
    "The page you were looking for could not be found at the given address."
  return (
    <>
      <Metadata pageTitle={title} pageDescription={description} />
      <div className={styles.container}>
        <div>
          <h1>{title}</h1>
          <p>{description}</p>
          <Link to="/">Back to homepage</Link>
        </div>
      </div>
    </>
  )
}
export default NotFoundPage
